import {
  createEffect,
  createSignal,
  onCleanup,
  Show,
  type JSX,
} from "solid-js";
import { useFastingService } from "../fasting.service";
import { currentTime, setCurrentTime } from "../signals/currentTime";

export function FastClock() {
  const SECOND = 1000;
  const MINUTE = SECOND * 60;
  const HOUR = 60 * MINUTE;

  const { startTime, isFasting, start, stop } = useFastingService();

  let rAF: number;
  createEffect(() => {
    if (!isFasting()) return;

    setCurrentTime(Date.now());
    const updateFastingTime = () => {
      setCurrentTime(Date.now());
      rAF = requestAnimationFrame(updateFastingTime);
    };

    rAF = requestAnimationFrame(updateFastingTime);

    onCleanup(() => {
      cancelAnimationFrame(rAF);
    });
  });

  const fastingHours = () => {
    const fastingTime = currentTime() - startTime();
    const hours = Math.floor(fastingTime / HOUR);
    return hours.toString().padStart(2, "0");
  };

  const fastingMinutes = () => {
    const fastingTime = currentTime() - startTime();
    const minutes = Math.floor((fastingTime % HOUR) / MINUTE);
    return minutes.toString().padStart(2, "0");
  };

  const fastingSeconds = () => {
    if (!isFasting()) {
      return "00";
    }

    const fastingTime = currentTime() - startTime();
    const seconds = Math.floor((fastingTime % MINUTE) / SECOND);
    return seconds.toString().padStart(2, "0");
  };

  const [startHours, setStartHours] = createSignal("00");
  const [startMinutes, setStartMinutes] = createSignal("00");

  const onClockFocus: JSX.EventHandler<HTMLSpanElement, FocusEvent> = (e) => {
    const range = document.createRange();
    range.selectNodeContents(e.target.childNodes[0]);
    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
  };

  const onClockInput: JSX.EventHandler<HTMLSpanElement, InputEvent> = (e) => {
    if (!e.target.textContent) return;

    if (/[^0-9]/.test(e.target.textContent)) {
      // TODO Add another methods to not interrupt user interaction
      // alert("It's not a number!");
      return;
    }
  };

  return (
    <article class="text-center">
      <h2 class="w-0 h-0 overflow-hidden">Fast Clock</h2>

      <p class="text-7xl font-bold" aria-live="polite">
        <span
          onFocus={onClockFocus}
          onInput={onClockInput}
          onBlur={(e) => {
            const textContent = e.target.textContent;
            if (!textContent || Number.isNaN(parseInt(textContent, 10))) {
              e.target.textContent = "00";
              setStartHours("00");
              return;
            }

            const num = parseInt(textContent, 10);
            if (num < 0) {
              e.target.textContent = "00";
              setStartHours("00");
              return;
            }

            if (num >= 1000) {
              e.target.textContent = "999";
              setStartHours("999");
              return;
            }

            const validText = textContent.replace(/[^0-9]/g, "");
            const newText = validText
              .slice(-3)
              .replace(/^0+/, "")
              .padStart(2, "0");

            e.target.textContent = newText;
            setStartHours(newText);
          }}
          contentEditable={!isFasting()}
          inputMode="numeric"
        >
          <Show when={isFasting()} fallback={startHours()}>
            {fastingHours()}
          </Show>
        </span>
        {":"}
        <span
          onFocus={onClockFocus}
          onInput={onClockInput}
          onBlur={(e) => {
            const textContent = e.target.textContent;
            if (!textContent || Number.isNaN(parseInt(textContent, 10))) {
              e.target.textContent = "00";
              setStartMinutes("00");
              return;
            }

            const num = parseInt(textContent, 10);
            if (num < 0) {
              e.target.textContent = "00";
              setStartMinutes("00");
              return;
            }

            if (num >= 60) {
              e.target.textContent = "59";
              setStartMinutes("59");
              return;
            }

            const validText = textContent.replace(/[^0-9]/g, "");
            const newText = validText
              .slice(-2)
              .replace(/^0+/, "")
              .padStart(2, "0");

            e.target.textContent = newText;
            setStartMinutes(newText);
          }}
          contentEditable={!isFasting()}
          inputMode="numeric"
        >
          <Show when={isFasting()} fallback={startMinutes()}>
            {fastingMinutes()}
          </Show>
        </span>
        <small class="text-2xl font-bold">{fastingSeconds()}</small>
      </p>

      <button
        class="mt-4 btn btn-lg"
        classList={{
          "btn-primary": !isFasting(),
          "btn-error": isFasting(),
        }}
        aria-live="polite"
        onClick={() => {
          if (isFasting()) {
            stop();
            return;
          }

          start(parseInt(startHours(), 10), parseInt(startMinutes(), 10));
          setStartHours("00");
          setStartMinutes("00");
        }}
      >
        <Show when={isFasting()} fallback={"Start"}>
          Stop
        </Show>
      </button>
    </article>
  );
}
